/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid } from 'theme-ui';
import SectionHeader from 'components/section-header';
import FeatureCard from 'components/feature-card.js';
import Performance from 'assets/feature/performance.svg';
import Partnership from 'assets/feature/partnership.svg';
import Subscription from 'assets/feature/subscription.svg';
import Support from 'assets/feature/support.svg';

const data = [
  {
    id: 1,
    imgSrc: Performance,
    altText: 'Fast Learning-Fast Earning',
    title: 'Fast Learning-Fast Earning',
    text:
      'OSS provide Fast Learning-Fast Earning platform free of cost. If you have already know any type of skills which is related IT indrustry, you can upload video via our platform after verify you can earn money instant.',
  },
  {
    id: 2,
    imgSrc: Partnership,
    altText: 'All Subscriptions Free',
    title: 'All Subscriptions Free',
    text:
      'We believe its important for everyone to access an account as a mentor or as a learner.when it comes to online digital learning we will be guiding and navigated by us.',
  },
  {
    id: 3,
    imgSrc: Subscription,
    altText: 'YouTubers can work as mentor',
    title: 'YouTubers can work as mentor',
    text:
      'If you want to get more subscirbers or users without any invesment. OSS can do for you, Just make register as mentor with your category or your own youTube Channle URL.',
  },
  {
    id: 4,
    imgSrc: Support,
    altText: 'Create your own Community',
    title: 'Create your own Community & Earn Money',
    text:
      'OSS is giving the opportunity to any buddy can create their own community. If found by OSS in your own community with 10K users already added then you will be eligible to earn 10K money instant.',
  },
];

export default function Feature() {
  return (
    <section sx={{ variant: 'section.feature' }}>
      <Container>
        <SectionHeader
          // slogan="OSS Quality features"
          title="Amazing useful features"
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCard
              key={item.id}
              src={item.imgSrc}
              alt={item.altText}
              title={item.title}
              text={item.text}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    pt: [0, null, null, null, null, null, 2],
    px: [5, 6, 0, null, 7, 8, 7],
    gridGap: [
      '40px 0',
      null,
      '45px 30px',
      null,
      '60px 50px',
      '70px 50px',
      null,
      '80px 90px',
    ],
    gridTemplateColumns: ['repeat(1,1fr)', null, 'repeat(2,1fr)'],
  },
};
