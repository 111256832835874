/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Box, Heading, Text, Image, Button } from 'theme-ui';
import girl from 'assets/key-feature/community.webp';
import boy from 'assets/team/member-3.png';
import ShapeLeft from 'assets/shape-left.webp';
import ShapeRight from 'assets/shape-right.webp';
import { Link } from 'components/link';

export default function LiveCard() {
  return (
    <section sx={styles.banner} id="home">
      <Container sx={styles.banner.container}>
        

        <Box sx={styles.banner.imageBox}>
        <Box sx={styles.banner.contentBox}>
            <Box sx={styles.banner.card}>
                <Box sx={styles.banner.imgBx}>
                    <Image sx={styles.banner.img} src={girl} alt="banner" />
                </Box>
                <Box sx={styles.banner.content}>
                    <Link path = '/osschat'>
                    <Box sx={styles.banner.content.details}>
                        <h2>Create Your Own Live Community <br/>
                            <span>Lets Connect</span>
                        </h2>
                        <Box sx={styles.banner.content.details.data}>
                            <h3>342 <br/> <span>Posts</span></h3>
                            <h3>120k<br/> <span>Followers</span></h3>
                            <h3>285k<br/> <span>Following</span></h3>
                        </Box>
                        <Box sx={styles.banner.content.details}>                            
                        </Box>
                    </Box>
                    </Link>
                </Box>
            </Box>            
        </Box>
        </Box>

      </Container>
    </section>
  );
}

const styles = {
  banner: {
    pt: ['140px', '145px', '155px', '170px', null, null, '180px', '215px'],
    pb: [2, null, 0, null, 2, 0, null, 5],
    position: 'relative',
    zIndex: 2,
    '&::before': {
      position: 'absolute',
      content: '""',
      bottom: 6,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: -1,     
     /* backgroundImage: `url(${ShapeLeft})`, */
      backgroundRepeat: `no-repeat`,
      backgroundPosition: 'bottom left',
      backgroundSize: '36%',
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      bottom: '40px',
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: -1,
     /* backgroundImage: `url(${ShapeRight})`, */
      backgroundRepeat: `no-repeat`,
      backgroundPosition: 'bottom right',
      backgroundSize: '32%',
    },    
    container: {
      marginTop: ['-94px'],
      minHeight: 'inherit',
      display: 'flex',     
      flexDirection: 'column',
      justifyContent: 'center',      
    },
    contentBox: {
      display: 'flex',
      position: 'relative',
      top: '25px',
      columnGap: '20px',      
      width: ['100%', '90%', '535px', null, '57%', '60%', '68%', '60%'],
      mx: 'auto',
      textAlign: 'center',
      mb: ['40px', null, null, null, null, 7],
    },
    card: {
        position: 'relative',
        width: '375px',
        height: '260px',
        backgroundColor: '#F86E04',
        borderRadius: '20px',
        boxShadow: ['0', '35px', '80px', 'rgba(0,0,0,0.15)'],
        Transition:'0.5s',
        '&:hover': {
            height: '220px',
            transform: 'translateY(0px)',            
          },                       
    },
    content: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        overFlow: 'hidden',
        details: {
            padding: '50px',
            textAlign: 'center',
            width: '100%',
            Transition: '0.5s',
            transform: 'translateY(150px)',            
            h2: {
                fontSize: '1.25em',
                fontWeight: '600',
                color: '#fff',
                lineHeight: '1.25em',
                span: {
                    color: '#fff',
                    fontSize: '0.75em',
                    fontWeight: 'bold',
                    
                },
            },            
            data:{
                display: 'flex',
                justifyContent: 'space-between',
                margin: ['20px',0],
                h3: {
                    fontSize: '1em',
                    color: '#fff',
                    lineHeight: '1.1em',
                    fontWeight: '600',
                    span: {
                        color: '#000',
                        fontSize: '0.85em',
                        fontWeight: '600',
                        
                    }
                }
            },
           /* actionBtn:{
                display: 'flex',
                columnGap: '50px',
                justifyContent: ['space-between', 'center'],                
                button: {
                    padding: ['10px', '10px'],
                    borderRadius: '5px',
                    border: 'none',
                    outline: 'none',
                    fontSize: '1em',
                    fontWeight: '500',
                    backgroundColor: '#ff5f95',
                    color: '#fff',
                    cursor: 'pointer',
                    '&:nth-of-type(2)':{
                        border: '1px solid #999',
                        color: '#999',
                        backgroundColor: '#fff',

                    }
                }
            } */
        },
    }, 
    
    
    imgBx: {
        position: 'absolute',
        left: '50%',
        top: '-30px',
        transform: 'translateX(-50%)',
        width: '150px',
        height: '150px',
        backgroundColor: '#5b5e64',
        borderRadius: '20px',
        boxShadow: ['0', '15px', '50px', 'rgba(0,0,0,0.35)'],
        overFlow: 'hidden',
        Transition: '0.5s',
        img: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
    },
    
    imageBox: {
      justifyContent: 'center',
      textAlign: 'center',
      display: 'inline-flex',
      mb: [0, null, -6, null, null, '-40px', null, -3],
      img: {
        position: 'relative',
        height: [245, 'auto'],
      },
    },
  },
};
