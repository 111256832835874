/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid } from 'theme-ui';
import SectionHeader from '../components/section-header';
import FeatureCardColumn from 'components/feature-card-column.js';
/* import Performance from 'assets/key-feature/performance.svg'; 
 import Partnership from 'assets/key-feature/partnership.svg'; 
 import Subscription from 'assets/key-feature/subscription.svg';
import Support from 'assets/key-feature/support.svg'; */
import Reactjs from 'assets/key-feature/reactjs.png';
import Nextjs from 'assets/key-feature/nextjs.png';
import Nodejs from 'assets/key-feature/nodejs.png';
import Python from 'assets/key-feature/python.svg';
import Aws from 'assets/key-feature/aws.svg';


const data = [
  {
    id: 1,
    imgSrc: Reactjs,
    altText: 'ReactJs',
    title: 'ReactJs Training',
    text:
      'In this training will train you to build efficient React applications by mastering the concepts of React. You will learn how to build simple components & integrate them into more complex design components.',
  },
  {
    id: 2,
    imgSrc: Nextjs,
    altText: 'NextJs',
    title: 'NextJs Training',
    text:
      'In this training will train you to build efficient NextJs applications by mastering the concepts of UI architecture. You will learn how to build dynamic components & integrate them into more complex HOC design concepts.',
  },
  {
    id: 3,
    imgSrc: Nodejs,
    altText: 'NodeJs',
    title: 'NodeJs Training',
    text:
      'Node.js is a very powerful JavaScript-based platform built on Google Chrome JavaScript V8 Engine. It is used to develop I/O intensive web applications like video streaming sites, single-page applications, and other web applications. Node.js is open source, completely free, and used by thousands of developers around the world.',
  },
  {
    id: 4,
    imgSrc: Python,
    altText: 'Python',
    title: 'Python Traning',
    text:
      'Python is an interpreted programming language, this means that as a developer you write Python (.py) files in a text editor and then put those files into the python interpreter to be executed.',
  },
  {
    id: 5,
    imgSrc: Aws,
    altText: 'AWS Cloud Training',
    title: 'AWS Cloud Traning',
    text:
      'Explore training and resources that help you gain an overall understanding of AWS - the world’s most comprehensive and broadly adopted cloud platform. Build your competence, confidence, and credibility with AWS Training. We will be learn on AWS various services like EC2 Instance, Laymbda Service,API Gateway etc.',
  }, 
 
];

export default function KeyFeature() {
  return (
    <section sx={{ variant: 'section.keyFeature' }} id="training">
      <Container>
        <SectionHeader sx={styles.slogan}
          slogan="Join Free Virtual Training Courses Programe"
          title="OSS Free Virtual Trainings on Different Technologies"
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCardColumn
              key={item.id}
              src={item.imgSrc}
              alt={item.altText}
              title={item.title}
              text={item.text}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  slogan: {
    fontWeight: 'bold',   
  },
  grid: { 
    display: 'grid',
    width: ['100%', '80%', '100%'],
    mx: 'auto',
    gridGap: [
      '35px 0',
      null,
      '40px 40px',
      '50px 60px',
      '30px',
      '50px 40px',
      '55px 90px',
    ],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(4,1fr)',
    ],
  },
};
